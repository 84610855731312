import * as React from 'react';
import TextField from '@mui/material/TextField';
import MainHeader from './MainHeader'
import { Helmet } from 'react-helmet';
import MainFooter from './MainFooter'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import './App.css';



const county = [
  {"value": "notmncounty", "label": "Not a MN county"},
  {"value": "hennepin", "label": "Hennepin"},
  {"value": "aitkin", "label": "Aitkin"},
  {"value": "anoka", "label": "Anoka"},
  {"value": "becker", "label": "Becker"},
  {"value": "beltrami", "label": "Beltrami"},
  {"value": "benton", "label": "Benton"},
  {"value": "big stone", "label": "Big Stone"},
  {"value": "blue earth", "label": "Blue Earth"},
  {"value": "brown", "label": "Brown"},
  {"value": "carlton", "label": "Carlton"},
  {"value": "carver", "label": "Carver"},
  {"value": "cass", "label": "Cass"},
  {"value": "chippewa", "label": "Chippewa"},
  {"value": "chisago", "label": "Chisago"},
  {"value": "clay", "label": "Clay"},
  {"value": "clearwater", "label": "Clearwater"},
  {"value": "cook", "label": "Cook"},
  {"value": "cottonwood", "label": "Cottonwood"},
  {"value": "crow wing", "label": "Crow Wing"},
  {"value": "dakota", "label": "Dakota"},
  {"value": "dodge", "label": "Dodge"},
  {"value": "douglas", "label": "Douglas"},
  {"value": "faribault", "label": "Faribault"},
  {"value": "fillmore", "label": "Fillmore"},
  {"value": "freeborn", "label": "Freeborn"},
  {"value": "goodhue", "label": "Goodhue"},
  {"value": "grant", "label": "Grant"},
  {"value": "houston", "label": "Houston"},
  {"value": "hubbard", "label": "Hubbard"},
  {"value": "isanti", "label": "Isanti"},
  {"value": "itasca", "label": "Itasca"},
  {"value": "jackson", "label": "Jackson"},
  {"value": "kanabec", "label": "Kanabec"},
  {"value": "kandiyohi", "label": "Kandiyohi"},
  {"value": "kittson", "label": "Kittson"},
  {"value": "koochiching", "label": "Koochiching"},
  {"value": "lac qui parle", "label": "Lac qui Parle"},
  {"value": "lake", "label": "Lake"},
  {"value": "lake of the woods", "label": "Lake of the Woods"},
  {"value": "le sueur", "label": "Le Sueur"},
  {"value": "lincoln", "label": "Lincoln"},
  {"value": "lyon", "label": "Lyon"},
  {"value": "mahnomen", "label": "Mahnomen"},
  {"value": "marshall", "label": "Marshall"},
  {"value": "martin", "label": "Martin"},
  {"value": "mcleod", "label": "McLeod"},
  {"value": "meeker", "label": "Meeker"},
  {"value": "mille lacs", "label": "Mille Lacs"},
  {"value": "morrison", "label": "Morrison"},
  {"value": "mower", "label": "Mower"},
  {"value": "murray", "label": "Murray"},
  {"value": "nicollet", "label": "Nicollet"},
  {"value": "nobles", "label": "Nobles"},
  {"value": "norman", "label": "Norman"},
  {"value": "olmsted", "label": "Olmsted"},
  {"value": "otter tail", "label": "Otter Tail"},
  {"value": "pennington", "label": "Pennington"},
  {"value": "pine", "label": "Pine"},
  {"value": "pipestone", "label": "Pipestone"},
  {"value": "polk", "label": "Polk"},
  {"value": "pope", "label": "Pope"},
  {"value": "ramsey", "label": "Ramsey"},
  {"value": "red lake", "label": "Red Lake"}
];


const defaultTheme = createTheme();



export default function Contact() {


  return (

    <ThemeProvider theme={defaultTheme}>

<Helmet> <title>AiMPLS - Contact Us</title>  </Helmet>

    <CssBaseline />


            <MainHeader/>



    <main>
      {/* Hero unit */}
   
      <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">

         

            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
           Contact Us
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Fill out the form and we will get back to you ASAP!
            </Typography>
   

          </Container>
        </Box>
    
    <Container maxWidth="sm">
    <Box
    
        component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"

      >   
      <div>
      <TextField
          required
          id="filled-helperText"
          label="Name"
          defaultValue=""
          helperText=""
          variant="filled"
        />
        <TextField
          required
          id="filled-helperText"
          label="Last Name"
          defaultValue=""
          helperText=""
          variant="filled"
        />
        
        <TextField
          required
          id="filled-helperText"
          label="Email"
          defaultValue=""
          helperText=""
          variant="filled"
        />

        <TextField
          required
          id="filled-helperText"
          label="Phone Number"
          defaultValue=""
          helperText=""
          variant="filled"
        />

        <TextField
          required
          select
          id="filled-helperText"
          label="County"
          defaultValue=""
          helperText=""
          variant="filled"
        >
        {
        
        county.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
          
          }
         </TextField>



         <TextField
          id="filled-textarea"
          label="Message"
          placeholder="Write here your message"
          multiline
          variant="filled"
        />

    <Stack spacing={2} direction="row" className="formButtons">
      <Button variant="outlined">Clear Form</Button>
      <Button variant="contained" >Submit Form</Button>
    </Stack>

      </div>


      </Box>   
    
      
        </Container>
       
      </main>
      {/* Footer */}
     
        

        <MainFooter/>

  
      {/* End footer */}
    
    </ThemeProvider>
  );
}