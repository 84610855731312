import Model1 from './models/watt_munisotaram_model.glb'
import CoverTemple from './images/cover_wattmunisotaram.jpg'


const Models = [
    {
     name: "Watt Munisotaram",
      img: CoverTemple,
      modelurl: Model1,
      content: "The Watt Munisotaram Buddhist temple in Hampton, Minnesota, was established in 1982 by the Minnesota Cambodian Buddhist Society. This is the largest buddhist temple in North America and is a place for meditation and worship for Minnesota Buddhists also this temple host visitors from all over the world and it covers 40 acres of land.",
      artist: "Cambodian Buddhist Society",
    }
];

export default Models;