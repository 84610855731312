import * as React from 'react';
import { Helmet } from 'react-helmet';
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { InstagramEmbed } from 'react-social-media-embed';



const defaultTheme = createTheme();

function About() {
  return (
    
    <ThemeProvider theme={defaultTheme}>

      <Helmet> <title>AiMPLS - About Us</title>  </Helmet>


      <CssBaseline />


              <MainHeader/>

    
    
      


      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">

         

            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
          About Us
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
           We want to make a significant impact by addressing the topic of Artificial Intelligence (AI) & Technological Advancements from an inclusive and accessible perspective.
            </Typography>
  

          </Container>
        </Box>


<Container maxWidth="md" >
        <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
         Mission
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Our mission is to close the technological advancement gap in communities by empowering them with critical information and skills. We want to provide accessibility, educational resources and support to enable individuals to get the most out of the technology. 
            </Typography>
            </Container>
 <Container maxWidth="md" className="css-10m0cap">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
         Goals
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Our goal is to open new opportunities for learning, growth, creativity and innovation. Ensuring that everyone, regardless of their background, has the tools that they need to thrive in the digital world. We are committed to build an inclusive and informed society where access to information and technology opens up a brighter future for everyone.
            </Typography>
            </Container>
            <Container maxWidth="md" >

         


<Typography variant="h2" align="center" color="text.secondary" paragraph>
Want to know more?
</Typography>
<Stack
  sx={{ pt: 0 }}
  direction="row"
  spacing={2}
  justifyContent="center"
>
  
  <Button variant="contained" href="/ai/contact">Contact Us</Button>

</Stack>

<div className="InstaBox">
<Typography variant="p" align="center" color="text.secondary" paragraph>
Follow Us on Instagram
</Typography>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  
  <InstagramEmbed url="https://www.instagram.com/ai.mpls" width={328} />
</div>
</div>
</Container>
      </main>
      {/* Footer */}
     
        

        <MainFooter/>

  
      {/* End footer */}
    
    </ThemeProvider>
  );

}





export default About;
