import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Icon from '@mui/material/Icon';





const YelpRecommendations = () => {
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get('https://api.yelp.com/v3/businesses/search', {
          headers: {
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Methods": "GET",
            'Content-Type': 'application/json',
            Authorization: "Bearer 2A84p91RQjWLblssw5mx0wd7gWCb52ZGW9dEYd1518AsM3C0Yhml7visKwKpGT0sw27rsXq5Izx3RsIdI1-9uD0-IIuG0BIKNDbTH3NMzyL0oJmEBrFiQi1glxnqZXYx",
          },
          params: {
            location: 'Minneapolis',
            offset: 0,
            categories: 'restaurants, comfortfood', // Customize this as needed
            limit: 9, // Adjust based on how many results you want
          },
        });

        setPlaces(result.data.businesses);
      } catch (error) {
        console.error('Error fetching data from Yelp:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container className="SuggestionsCont" >
       
       <Grid container spacing={4}>


        {places.map(place => ( 

<Grid item xs={12} sm={6} md={4}>
<Card
  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
>
  <CardMedia className="suggestcardimg"
    component="img"
    sx={{
      // 16:9
      pt: '0',
    }}
    image={place.image_url}
  />
  
 <CardContent sx={{ flexGrow: 1 }}>


<Typography gutterBottom variant="h5" component="h2">
    {place.name}
    </Typography>
    <Typography>
    {place.display_phone}
    </Typography>   

    <Typography>
    {place.location.address1}, {place.location.city} 
    </Typography>

    <Typography>


{ OpenClose(place.is_closed) }


</Typography>

    </CardContent>

  <CardActions>        
  <Button variant="outlined" target="_blank" href={place.url}> Profile</Button>
  <Button variant="contained" target="_blank" href={`http://maps.google.com/?ll=${place.coordinates.latitude},${place.coordinates.longitude}&q=${place.name}`}><Icon baseClassName="fas" className="GetThere fa-solid fa-route" color="primary" />  </Button>
  </CardActions>


</Card>
</Grid>
          
       
        ))}


   </Grid>
   </Container>
  );
};


function OpenClose(place){
  
if(!place.is_closed)
return (<Button variant="contained" className="btnshift" color="success">
Open
</Button>);
else
return (<Button variant="outlined" className="btnshift" color="error">
Closed
</Button>);

}
  
  
  export default YelpRecommendations;
