import * as React from 'react';
import Home from './Home';
import SculptureGarden from './SculptureGardenViewer';
import WattMunisotaram from './WattMunisotaramViewer';
import MainViewer from './viewer';
import Contact from './contact';
import Media from './Media';
import ImgGen from './ImageGenerator';
import About from './about';




import {BrowserRouter, Routes, Route} from "react-router-dom";
 
 



function App() {
  return (
    <BrowserRouter basename="/ai">
    <Routes>
      <Route index element={<Home/>} />
      <Route path="/" element={<Home/>} />
      <Route path="/viewer" element={<MainViewer/>} />
      <Route path="/SculptureGarden" element={<SculptureGarden />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/media" element={<Media />} />
      <Route path="/about" element={<About />} />
      <Route path="/imggenerator" element={<ImgGen />} />
      <Route path="/WattMunisotaram" element={<WattMunisotaram />} />
     </Routes>
    </BrowserRouter>
   
  );

  


}





export default App;
