import React, { useState, useEffect } from 'react';
import axios from 'axios';
import yelplogo from './images/yelplogo.png';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Icon from '@mui/material/Icon';

const YelpRecommendations = () => {
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // https://cors-anywhere.herokuapp.com/
        const result = await axios.get('https://aimpls.com/ai/callback.php?request=places', {
          headers: {
            'Content-Type': 'application/json',
            },
          params: {
           },
        });

        setPlaces(result.data.businesses);
      } catch (error) {
        console.error('Error fetching data from Ai.Mpls:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container className="SuggestionsCont" >
       
       <Grid container spacing={4}>


        {places.map(place => ( 

<Grid item xs={12} sm={6} md={4}>
<Card
  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
>
  <div className="cardtitle-cont">
<Typography gutterBottom variant="h5" className="cardtitle" component="h2">
{place.name}
    </Typography>
    </div>
  <CardMedia className="suggestcardimg"
    component="img"
    sx={{
      // 16:9
      pt: '0',
    }}
    image={place.image_url}
    title={place.name}
  />
  
 <CardContent sx={{ flexGrow: 1 }}>



    <Typography>
    {place.display_phone}
    </Typography>   

    <Typography>
    {place.location.address1}, {place.location.city}, {place.location.state},  {place.location.zip_code}
    </Typography>

    <Typography>


{ DisplayRating(place.rating) } <b>Rating:</b> {place.rating}

{ OpenClose(place.is_closed) }


</Typography>
         <a target="_blank" href="https://www.yelp.com/"><img src={yelplogo} className="yelplogo" /></a>
    </CardContent>

  <CardActions>        
  <Button variant="outlined" target="_blank" href={place.url}> Profile</Button>
  <Button variant="contained" target="_blank" href={`http://maps.google.com/?ll=${place.coordinates.latitude},${place.coordinates.longitude}&q=${place.name}`}><Icon baseClassName="fas" className="GetThere fa-solid fa-route" color="primary" />  </Button>
  </CardActions>


</Card>
</Grid>
          
       
        ))}


   </Grid>
   </Container>
  );
};


function OpenClose(place){
  
if(!place)
return (<Button variant="contained" className="btnshift" color="success">
Open
</Button>);
else
return (<Button variant="outlined" className="btnshift closed" color="error">
Closed
</Button>);

}



function DisplayRating(rating){
  
  if(rating >= 0 && rating < 0.5)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_16_0@2x.png'}/>);

  if(rating >= 0.5 && rating < 1)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_16_half@2x.png'}/>);

  if(rating >= 1 && rating < 2)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_2@2x.png'}/>);

  if(rating >= 2.5 && rating < 3)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_2_half@2x.png'}/>);

  if(rating >= 3 && rating < 3.5)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_3@2x.png'}/>);

  if(rating >= 3.5 && rating < 4)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_16_3_half@2x.png'}/>);
 
  if(rating >= 4 && rating < 4.5)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_16_4@2x.png'}/>);
  
  if(rating >= 4.5 && rating < 5)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_16_4_half@2x.png'}/>);

  if(rating = 5)
  return (<img className="ratingimg" src={'/ai/yelp_review_imgs/Review_Ribbon_small_16_5@2x.png'}/>);


 
}
  

  
  export default YelpRecommendations;
