import * as React from 'react';
import { Helmet } from 'react-helmet';
import Locations from './ViewerLocations';
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import FullScreenDialog from './GoToLocation';
import SuggeCard from './SuggestionsPlaces';
import SuggeCardFood from './SuggestionsFood';


const defaultTheme = createTheme();


function Home() {

  

  return (
    
    <ThemeProvider theme={defaultTheme}>
       <Helmet> <title>AiMPLS</title>  </Helmet>
      <CssBaseline />


              <MainHeader/>



      <main >
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 9,
            pb: 6,
          }}
          className="BannerHome">
          <Container maxWidth="sm" >

         

            <Typography 
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
           Ai<b>MPLS</b>
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
           Welcome to this experimental website.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              
             
              <Button variant="contained" href="/ai/contact">Contact Us</Button>
          
              </Stack>
              <Stack
              sx={{ pt: 1 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              
      
 
              </Stack>

          </Container>
        </Box>
        <Container maxWidth="md"> 
        
        
        
        
        <Container maxWidth="md">
 
    </Container>


        <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
          3D Models viewer
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
           Select a location to view the models
            </Typography>
            </Container>
        <Container sx={{ py: 8 }} maxWidth="md">

          {/* End hero unit */}
          <Grid container spacing={4}>




            {Locations.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={6}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia className="mediaplacecard"
                    component="img"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={card.img}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.name}
                    </Typography>
                    <Typography>
                        {card.content}
                    </Typography>
                    <Typography>
                      {card.manage}
                    </Typography>
                    </CardContent>

                  <CardActions>        
                    <FullScreenDialog model={card}/>
                  </CardActions>
                </Card>
              </Grid>
            ))}




          </Grid>

          </Container>
          <Container className="whatToDo" >

         

          <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
            What to do In Minneapolis
            </Typography>
            <Typography variant="p" align="center" color="text.secondary" paragraph>  
            Powered by Yelp!
            </Typography>


                 <SuggeCard/>
           
           
            </Container>
      
 
        
      </main>
      {/* Footer */}
     
        

        <MainFooter/>

  
      {/* End footer */}
    
    </ThemeProvider>
  );

  


}





export default Home;
