
import SculptureGardenCover from './images/img003.jpg'
import CoverTemple from './images/cover_wattmunisotaram.jpg'


const Locations= [
    {
     name: "Minneapolis Sculpture Garden",
      img: SculptureGardenCover,
      location: "/ai/SculptureGarden",
      manage: "Manage by: Walker Art Center",
      content: "Located in Minneapolis"
    }
    ,{
     name: "Watt Munisotaram",
      img: CoverTemple,
      location: "/ai/WattMunisotaram",
      manage: "Manage by: Cambodian Buddhist Society",
      content: "Located in Dakota, Minnesota"
    }
  ];

  export default Locations;