import * as React from 'react';
import { Helmet } from 'react-helmet';
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import { InstagramEmbed } from 'react-social-media-embed';
import { styled } from '@mui/material/styles';


const defaultTheme = createTheme();


function Home() {

  

  return (
    
    <ThemeProvider theme={defaultTheme}>
       <Helmet> <title>AiMPLS - MultiMedia</title>  </Helmet>
      <CssBaseline />


              <MainHeader/>



      <main >
        {/* Hero unit */}
        <Box className="BannerHome minMainBannerHeight MediaCoverHome"
          sx={{
            bgcolor: 'background.paper',
            pt: 13,
            pb: 6,
          }}
          >
          <Container maxWidth="sm" >

         

            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
           Multi<b>Media</b>
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
           Animations, Timelapses, Aerial Footage & More...
            </Typography>
   

          </Container>
        </Box>
       


 <Container sx={{ py: 8 }} maxWidth="md">





 <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
          Multimedia Development
            </Typography>



  </Container>



 <Container sx={{ py: 0 }} maxWidth="md">
  


<Grid container spacing={0} >






<Paper
      sx={{
        p: 2,
        margin: 'auto',
        marginTop:2,
       
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          
          <InstagramEmbed url="https://www.instagram.com/reel/C2XjQ6uOqOA/" width={328} />
       
        </Grid>




        <Grid item xs={12} sm container>
             <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
               Downtown Minneapolis Hyperlapse
              </Typography>
              <Typography variant="body2" gutterBottom>
              Timelapse + Editing + Generative Ai
              </Typography>
                            
              <Typography color="text.secondary" sx={{ marginTop:1, marginBottom:1 }}>

                
                Compilation of videos, photos and sound effects around Downtown Minneapolis.

                
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Video Edition
              </Typography>

              <Typography sx={{ cursor: 'pointer', marginTop:2 }} variant="body2">
              <Button variant="outlined" target="_blank" href="https://www.instagram.com/reel/C2XjQ6uOqOA/">View on Instagram</Button>
              </Typography>


            </Grid>
           
            
    


            
          </Grid>
          <Grid item>
            
            
            
            
            <Typography variant="subtitle1" component="div">
          
            </Typography>
          </Grid>
        </Grid>

      </Grid>
</Paper>






<Paper
      sx={{
        p: 2,  
        margin: 'auto',
        marginTop:2,
       
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          
          <InstagramEmbed url="https://www.instagram.com/reel/C3-keDIA4AZ/" width={328} />
       
        </Grid>




        <Grid item xs={12} sm container>
             <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                Ai Animation
              </Typography>
              <Typography variant="body2" gutterBottom>
                AnimateDiff + Video Edition
              
              
              
              <Typography color="text.secondary" sx={{ marginTop:1, marginBottom:1 }}>

                
              Collaboration with a group of creative people from different places of the world

                
              </Typography>

              </Typography>
              <Typography variant="body2" color="text.secondary">
                Generative Ai + Stable Diffusion + Video Editing
              </Typography>

              <Typography sx={{ cursor: 'pointer', marginTop:2 }} variant="body2">
              <Button variant="outlined" target="_blank" href="https://www.instagram.com/reel/C3-keDIA4AZ/">View on Instagram</Button>
              </Typography>

            </Grid>

 
          </Grid>
          <Grid item>
            
            
            
            
            <Typography variant="subtitle1" component="div">
          
            </Typography>
          </Grid>
        </Grid>

      </Grid>
</Paper>



    <Paper
      sx={{
        p: 2,  
        margin: 'auto',
        marginTop:2,
       
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          
          <InstagramEmbed url="https://www.instagram.com/reel/C1hkwflgicK/" width={328} />
       
        </Grid>




        <Grid item xs={12} sm container>
             <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                Generative Ai Recap 2023
              </Typography>
              <Typography variant="body2" gutterBottom>
                AnimateDiff + Video Edition
              
              
              
              <Typography color="text.secondary" sx={{ marginTop:1, marginBottom:1 }}>

                
              Small recap showing the progress and results in this year.

                
              </Typography>

              </Typography>
              <Typography variant="body2" color="text.secondary">
                Generative Ai + Stable Diffusion
              </Typography>

              <Typography sx={{ cursor: 'pointer', marginTop:2 }} variant="body2">
              <Button variant="outlined" target="_blank" href="https://www.instagram.com/reel/C1hkwflgicK/">View on Instagram</Button>
              </Typography>

            </Grid>

 
          </Grid>
          <Grid item>
            
            
            
            
            <Typography variant="subtitle1" component="div">
          
            </Typography>
          </Grid>
        </Grid>

      </Grid>
</Paper>





<Paper
      sx={{
        p: 2,
        margin: 'auto',
        marginTop:2,
       
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          
          <InstagramEmbed url="https://www.instagram.com/p/C20NjubAfE4/" width={328} />
       
        </Grid>




        <Grid item xs={12} sm container>
             <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
               Video Editing
              </Typography>
              <Typography variant="body2" gutterBottom>
                Generative Ai + Video Edition
              
              
                        
              <Typography color="text.secondary" sx={{ marginTop:1, marginBottom:1 }}>

                
               Video made with Generative Ai that reach 2 million view in the platform.

                
              </Typography>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Video Edition
              </Typography>

              <Typography sx={{ cursor: 'pointer', marginTop:2 }} variant="body2">
              <Button variant="outlined" target="_blank" href="https://www.instagram.com/p/C20NjubAfE4/">View on Instagram</Button>
              </Typography>


            </Grid>
           
            
    


            
          </Grid>
          <Grid item>
            
            
            
            
            <Typography variant="subtitle1" component="div">
          
            </Typography>
          </Grid>
        </Grid>

      </Grid>
</Paper>






  </Grid>



</Container>



        
        
        
        <Container maxWidth="md" >

         
  



</Container>
 




      </main>
      {/* Footer */}
     
        

        <MainFooter/>

  
      {/* End footer */}
    
    </ThemeProvider>
  );

  


}





export default Home;
