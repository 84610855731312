import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function MainFooter() {
    return (

      <>

<Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">


   <Typography variant="h6" align="center" gutterBottom>
          
          </Typography>

      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://aimpls.com/">
          Ai.Mpls
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>

      

      <Typography className="credits" 
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          w/&#60;3 by Csar!
        </Typography>
        <Link color="inherit" target="_blank" href="https://www.cs.com.ve/p5364.html"><img className="cslogo" src={'https://www.cs.com.ve/images/logo-cs-b.png'} /></Link>
</Box>
      </>
    );
  }




  export default MainFooter;