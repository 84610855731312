import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';


import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

var clave = process.env.REACT_APP_OPENAI_API_KEY;
const defaultTheme = createTheme();

const ImageGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const handleChange = (event) => {
    setPrompt(event.target.value);
  };

  const onReCAPTCHAChange = (token) => {
   



    setRecaptchaToken(token);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (recaptchaToken) {
      alert('Please verify you are a human!');
      return;
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/images/generations',
        {
          prompt: "a house in the sea",
          n: 1,
          size: "768x768"
        },
        {
          headers: {
            'Authorization': `Bearer ${clave}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.data.length > 0) {
        setImageSrc(response.data.data[0].url);
      }
    } catch (error) {
      
      console.error('Error generating image:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={prompt}
          onChange={handleChange}
          name="prompt"
          placeholder="Enter a prompt"
        />
        <ReCAPTCHA
          sitekey="6LcwKRITAAAAAFbTMxKRPZW4h4YSHEccYBlxXQUe"
          onChange={onReCAPTCHAChange}
        />
        <button type="submit">Generate Image</button>
      </form>
      {imageSrc && <img src={imageSrc} alt="Generated" />}
    </div>
  );
};



function Home() {

  

  return (
    
    <ThemeProvider theme={defaultTheme}>
       <Helmet> <title>AiMPLS - Image Generator</title>  </Helmet>
      <CssBaseline />


              <MainHeader/>



      <main >
        {/* Hero unit */}
        <Box className="minMainBannerHeight"
          sx={{
            bgcolor: 'background.paper',
            pt: 13,
            pb: 6,
          }}
          >
          <Container maxWidth="sm" >

         

            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
           Image<b>Generator</b>
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
           Create images using Artificial Intelligence
            </Typography>
   

          </Container>
        </Box>
       


 <Container sx={{ py: 8 }} maxWidth="md">





 <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >


    {clave}



            </Typography>



  </Container>



 <Container sx={{ py: 0 }} maxWidth="md">
  


</Container>



        
        
        
        <Container maxWidth="md" >

         
  
<ImageGenerator />


</Container>
 




      </main>
      {/* Footer */}
     
        

        <MainFooter/>

  
      {/* End footer */}
    
    </ThemeProvider>
  );

  


}





export default Home;
