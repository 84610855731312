import * as React from 'react';
import Button from '@mui/material/Button';
import ExploreIcon from '@mui/icons-material/Explore';


function GoToLocation({model}) {


return (
        
  
<Button variant="outlined" href={model.location}><ExploreIcon /> Open Location</Button>

     
    );
  }
 


  export default GoToLocation;
